* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  /* background-color: #81c2f8; */
}

/* this is just a container which contain all backgroud style
of div.glassbg */
@media screen {
  .glassbg {
    position: fixed;
    top: 0px;
    left: 0px;

    min-height: 100vh;
    width: 100%;

    background: linear-gradient(to bottom, rgb(225, 250, 252), rgb(255, 180, 180));
    z-index: -1;

    display: flex;
    justify-content: center;
  }

  @keyframes levitate {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-100px);
    }
  }

  .glassbg .color {
    position: absolute;
    filter: blur(150px);
  }

  .glassbg .color:nth-child(1) {
    top: -350px;
    height: 600px;
    width: 600px;
    background: #ff359b;
    animation: levitate 2s 0.1s infinite alternate;
  }

  .glassbg .color:nth-child(2) {
    bottom: -150px;
    left: 100px;
    height: 500px;
    width: 500px;
    background: #fffd87;
    animation: levitate 2s 0.4s infinite alternate;
  }

  .glassbg .color:nth-child(3) {
    bottom: 50px;
    right: 100px;
    height: 300px;
    width: 300px;
    background: #00d2ff;
    animation: levitate 2s 0.7s infinite alternate;
  }
}

#root {
  height: 100%;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}




.App-header {

  /* height: 40px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.269);
  backdrop-filter: blur(100px);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
}

.nav-bar {

  height: 80%;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-bar>div {
  display: flex;
  gap: 10px;
  align-items: center;
}

@media screen and (max-width: 700px) {
  .nav-bar {
    flex-direction: column;
    font-size: 15px;
  }

  .App-header {
    /* height: 100px; */
    padding: 5px;
  }
}

main {
  /* background-color: rgb(209, 226, 255); */
  padding: 10px;
  flex-grow: 1;
}

.main-div {
  height: 100%;
  max-width: 800px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.349);
  border-radius: 8px;
  margin: auto;
  border-top: thin solid rgb(255, 255, 255);
  border-left: thin solid rgb(255, 255, 255);
  border-right: thin solid rgb(163, 163, 163);
  border-bottom: thin solid rgb(130, 130, 130);
  box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}



.select-area {

  width: 90%;
  margin: 10px;
  /* padding: 10px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: thin solid rgb(170, 159, 138);
  border-radius: 5px;
  overflow: hidden;

}

input {
  width: 80%;
  padding: 8px;
  border-radius: 5px 0 0 5px;
  border: none;

  outline: none;
  flex-grow: 1;
  font-size: large;
  background-color: rgba(255, 255, 255, 0.596);

}


.search-button {

  width: 50px;
  padding: 8px;
  /* background-color: #81c2f8; */
  border: none;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.326);
}

.search-button:hover {
  background-color: rgba(255, 255, 255, 0.16);
}

.search-button>svg {
  color: black;
  font-size: 20px;
}


.view-info {
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.api-error {
  background-color: rgb(237, 128, 128);
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}

#city-name {
  font-size: 3rem;
}

.date-time {
  display: flex;
  color: rgb(170, 159, 138);
  margin-top: 12px;
}

.weather-info {
  margin-top: 10px;
  width: 50%;
  max-width: 80%;
  height: 200px;
  display: flex;
  gap: 15px;



}

.weather-type {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  justify-content: center;
  align-items: center;

}

.weather-temp {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.temperature {
  display: flex;
  gap: 20px;
}

#temp {
  font-size: 3rem;
}

#celcius {
  color: blue;
}

#temp-info {
  text-align: center;

}

.weather-data {
  color: rgb(170, 159, 138);
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px
}


/* addition info styles */

@media screen {
  .additional-info {
    padding: 20px;
    margin-top: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    /* background-color: #f9f9f9; */
  }

  .additional-info h3 {
    margin-bottom: 15px;
    font-size: 1.5em;
    color: #333;
  }

  .additional-info table {
    width: 100%;
    border-collapse: collapse;
  }

  .additional-info table,
  .additional-info th,
  .additional-info td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .additional-info th,
  .additional-info td {
    text-align: left;
  }

  .additional-info tr:nth-child(even) {
    background-color: (255, 255, 255, 0.5);
  }

  .additional-info tr:hover {
    background-color: #ddd;
  }

  .additional-info th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #4CAF50;
    color: white;
  }
}